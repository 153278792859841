.deleteModalMain {
  .deletemainHeaderDelete {
    height: 0px;
    .title {
      color: #24252b;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
    }

    .subtitle {
      padding-top: 5px;
      color: #b6b6b6;
      font-family: Albert Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 10px;
    }
  }

  .customModalBody {
    padding-left: 28px;
    padding-right: 28px;
    overflow: scroll;
  }

  .footerButton {
    display: flex;
    align-items: center;
    padding-top: 30px;

    .saveButton {
      cursor: pointer;
      width: 117px;
      height: 44px;
      flex-shrink: 0;
      border-radius: 22px;
      border: 1px solid #000;
      display: flex;
      background-color: #000;
      color: white;
      justify-content: center;
      align-items: center;
    }

    .cancelButton {
      cursor: pointer;
      margin-left: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 117px;
      height: 44px;
      flex-shrink: 0;
      border-radius: 22px;
      border: 1px solid #000;
      background: #fff;
    }
  }
}
