.welcomeMessage {
  .message1 {
    color: #00b3d1;

    font-family: Albert Sans;
    font-size: 42.947px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    /* 111.765% */
  }

  .message2 {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 42.947px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
  }

  .message3 {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.overlap-wrapper {
  height: 128px;
}

.overlap-3 {
  background: linear-gradient(
    180deg,
    rgb(34, 34, 34) 0%,
    rgb(58, 58, 58) 42.33%,
    rgb(18.06, 18.06, 18.06) 100%
  );
  border: 1px solid;
  border-color: #2c2c2c;
  border-radius: 8px;
  height: 128px;
  position: relative;
}

.child-education {
  color: #c9ffd4;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 69px;
  letter-spacing: 0;
  line-height: 34px;
  position: absolute;
  top: 19px;
}

.child-education-2 {
  color: #fefefe;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 300;
  left: 91px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 70px;
  width: 136px;
}

.child-education-3 {
  color: #fefefe;
  font-family: "Albert Sans", Helvetica;
  font-size: 40px;
  font-weight: 600;
  height: 39px;
  left: 17px;
  letter-spacing: 0;
  line-height: 63.6px;
  position: absolute;
  top: 67px;
  white-space: nowrap;
  width: 66px;
}

.child-education-4 {
  color: #fff0bc;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 69px;
  letter-spacing: 0;
  line-height: 34px;
  position: absolute;
  top: 19px;
  width: 178px;
}

.ellipse-3 {
  background-color: #c8ffd4;
  border-radius: 21px;
  height: 42px;
  left: 17px;
  position: absolute;
  top: 15px;
  width: 42px;
}

.schedule-update {
  position: absolute;
  border-radius: 8px;
  border: 1px solid #cbedf3;
  background: #e4fbff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11),
    0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  height: 320px;
}

.dashboard-main-container {
  height: calc(100vh - 100px);

  .table-heading {
    color: #000;
    font-family: Albert Sans;
    font-size: 42.947px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 111.765% */
    margin-bottom: 26px;
  }

  .monthly-analysis {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
    height: 369px;
    padding: 15px;
  }

  .monthly-analysis-heading {
    color: #24252b;
    text-align: center;
    font-family: Albert Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 120.833% */
  }

  .monthly-analysis-sub-heading {
    color: #b6b6b6;
    text-align: center;
    font-family: Albert Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .recently-added-drivers {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
    height: 391px;
    margin-top: 16px;
    padding: 15px;
  }

  .recently-added-drivers-heading {
    color: #24252b;
    font-family: Albert Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 120.833% */
  }

  .recently-added-drivers-sub-heading {
    color: #b6b6b6;
    font-family: Albert Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .recently-added-drivers-container {
    margin-top: 23px;
    display: flex;

    .recently-added-drivers-image {
      width: 52px;
      height: 52px;
      background-color: black;
      border-radius: 100%;
      display: flex;
      align-items: center;
    }

    .recently-added-drivers-name {
      font-family: Albert Sans;
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      margin-left: 10px;
    }

    .recently-added-drivers-id {
      color: #b6b6b6;
      font-family: Albert Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      margin-left: 10px;
    }

    .recently-added-drivers-added-info {
      color: #bdbdbd;
      text-align: right;
      font-family: Albert Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */
    }
  }
}
