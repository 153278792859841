.inputFormMain {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;

  .otpInput {
    width: 50px !important;
    height: 50px;
    margin: 0 4px;
    font-size: 12px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);

    @media (max-width: 576px) {
      width: 45px !important;
      height: 45px;
    }
  }
}

.resendOtpContainer {
  margin-top: 25px;
  line-height: 1;

  span {
    font-size: 12px;
    font-family: "Montserrat-bold";
    color: #bebfc2;
  }
}

.css-1fiywmn-MuiGrid-root {
  margin-left: 0px;
}
