.report-container {
  .report-heading-container {
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    .report-heading {
      color: #24252b;
      font-family: Albert Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 29px;
    }
  }

  .report-body-container {
    border: 2px solid #eee;
    height: 100%;

    .form-container {
      display: flex;
      margin: 10px;
      border-radius: 15px;
      padding: 15px;

      .form-sub-container {
        display: flex;
        justify-content: space-evenly;
        padding-inline: 10px;
        .asteric {
          color: red;
          text-align: center;
          vertical-align: middle;
          margin-left: 5px;
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .saveButton {
          cursor: pointer;
          width: 140px;
          height: 54px;
          border-radius: 22px;
          border: 1px solid #000;
          display: flex;
          background-color: #000;
          color: white;
          justify-content: center;
          align-items: center;
          border-radius: 22px;
          background: #000;
        }
      }
    }
  }
}

.table-conatainer {
  .table-sub-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #eee;

    .reportDetails {
      color: #24252b;
      font-size: 30px;
      font-weight: 600;
    }

    .button-donwload {
      margin-right: 50px;
      border-radius: 20px;
      &:hover {
        background-color: black;
      }

      color: white;
      background-color: black;
    }
  }
}
