.am {
  color: #24252b;
  text-align: center;
  font-family: Albert Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 200% */
}

.journey-main-container {
  .journey-main-container-2 {
    .journey-main-container-3 {
      .page-heading {
        color: #000;
        font-family: Albert Sans;
        font-size: 42.947px;
        font-style: normal;
        font-weight: 600;

        @media (max-width: 576px) {
          font-size: 30px;
        }
      }

      .journey-details-message-container {
        margin-top: 30px;
        padding: 37px;
        padding-left: 47px;
        background-image: url("../../../../assets/images/Journey_Details_BG_Image.jfif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media (max-width: 575.98px) {
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
          padding: 18px;
          margin-top: 10px;
        }

        .journey-details-subcontainer-message-1 {
          display: flex;
          justify-content: space-between;

          .journey-details-message-heading {
            color: #fff;
            font-family: Albert Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;

            @media (max-width: 575.98px) {
              font-size: 16px; // Example: Change font size for extra small screens
              font-weight: 500;
            }
          }
        }
      }

      .journey-details-container {
        margin-top: 30px;
        padding: 37px;
        padding-left: 47px;
        background-image: url("../../../../assets/images/Journey_Details_BG_Image.jfif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media (max-width: 575.98px) {
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
          padding: 18px;
          margin-top: 10px;
        }

        .journey-details-subcontainer-1 {
          display: flex;
          justify-content: space-between;

          .journey-details-heading {
            color: #fff;
            font-family: Albert Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;

            @media (max-width: 575.98px) {
              font-size: 16px; // Example: Change font size for extra small screens
              font-weight: 500;
            }
          }

          .journey-details-date {
            color: #fff;
            font-family: Albert Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;

            @media (max-width: 575.98px) {
              font-size: 12px; // Example: Change font size for extra small screens
              font-weight: 400;
            }
          }
        }

        .journey-details-button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;

          @media (max-width: 576px) {
            margin-top: 16px;
          }

          .journey-details-button {
            border-radius: 30px;
            border: 1px solid #079824;
            background: #e0ffe7;
            color: #079824;
            text-align: center;
            font-family: Albert Sans;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 196px;
            height: 42px;
          }
        }

        .journey-details-subcontainer-2 {
          margin-top: 12px;

          .current-stop-button {
            background: #00b3d1;
            color: #fff;
            font-family: Albert Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media (max-width: 575.98px) {
              margin-top: 12px;
              font-size: 12px;
            }
          }
        }

        .journey-details-container-2 {
          display: flex;

          @media (max-width: 575.98px) {
            display: block;
            margin-top: 5px;
          }

          .journey-details-stop-details {
            display: flex;
            align-items: flex-end;

            @media (max-width: 575.98px) {
              display: flex;
            }

            .journey-stop-name {
              color: #fff;
              font-family: Albert Sans;
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px;

              /* 81.25% */
              @media (max-width: 575.98px) {
                font-size: 22px;
              }
            }

            .journey-stop-time {
              color: #fff;
              font-family: Albert Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;
              margin-left: 10px;
              align-self: flex-end;
              /* 114.286% */

              @media (max-width: 575.98px) {
                color: #fff;
                font-family: Albert Sans;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                /* 160% */
              }
            }
          }

          .journey-details-stop-details-2 {
            margin-top: 12px;

            @media (max-width: 576px) {
              margin-top: 5px;
            }

            .journey-stop-address {
              color: #fff;
              font-family: Albert Sans;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              /* 114.286% */
            }
          }
        }
      }
    }

    .stop-details-container {
      margin-top: 33px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
      padding-left: 59px;
      padding-top: 11px;
      padding-right: 57px;

      @media (max-width: 576px) {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
        padding-top: 15px;
        margin-top: 0px;
        padding-left: 17px;
        padding-right: 16px;
      }

      .stop-details-heading-container {
        margin-top: 10px;

        .stop-details-heading {
          color: #000;
          font-family: Albert Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          /* 80% */

          @media (max-width: 576px) {
            color: #000;
            font-family: Albert Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            /* 114.286% */
          }
        }
      }

      .ReactedStop {
        opacity: 0.4;
        background: #f8f8f8;
        padding-top: 19px;
        padding-bottom: 20px;
      }

      .currentStop {
        background: #f8f8f8;
        padding-top: 10px;
        padding-bottom: 20px;
      }

      .UpcommingStop {
        opacity: 0.4;
        background: #ebe9ff;
        padding-top: 10px;
        padding-bottom: 29px;
      }

      .individual-stop-main-container {
        @media (max-width: 576px) {
          margin-top: 12px;
          border-radius: 8px;
          border: 1px solid #eee;
        }

        .individual-stop-details-container {
          .individual-reached-stop {
            padding-left: 34px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 576px) {
              padding-left: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid #eeee;
            }

            .individual-address-container {
              display: flex;
              justify-content: space-between;

              .user-details {
                box-shadow: 0px 0px 2px 0px rgba(49, 48, 48, 0.06);
                padding: 10px;
                border-radius: 5px;
                margin-top: 4px;
                background-color: white;
              }

              @media (max-width: 576px) {
                flex-direction: column;
              }

              .stop-1 {
                color: #24252b;
                font-family: Albert Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                /* 114.286% */

                @media (max-width: 576px) {
                  color: #24252b;
                  font-family: Albert Sans;
                  font-size: 13px;
                  padding-bottom: 2px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 16px;
                  /* 133.333% */
                }
              }

              .stop-2 {
                color: #24252b;
                font-family: Albert Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;

                /* 114.286% */
                @media (max-width: 576px) {
                  color: #000;
                  font-family: Albert Sans;
                  font-size: 11px;
                  padding-bottom: 2px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  /* 145.455% */
                }
              }

              .stop-3 {
                color: #24252b;
                font-family: Albert Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                /* 114.286% */

                @media (max-width: 576px) {
                  color: #000;
                  font-family: Albert Sans;
                  font-size: 11px;
                  padding-bottom: 2px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  /* 145.455% */
                }
              }
            }

            .previous-stop {
              color: #000;
              font-family: Albert Sans;
              font-size: 17px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              /* 200% */
            }

            .end-time {
              color: #24252b;
              font-family: Albert Sans;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              /* 118.182% */
              padding-block: 2px;
            }

            .start-time {
              color: #24252b;
              font-family: Albert Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              /* 160% */
            }
          }
        }

        // opacity: 0.4;
        // background: #F8F8F8;
      }

      .end-journey-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 34px;
        margin-top: 30px;

        .end-journey-button {
          border-radius: 29px;
          border: 1px solid #000;
          background: #000;
          height: 54.443px;
          color: #fff;
          font-family: Albert Sans;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
