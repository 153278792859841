.profile-pic {
    width: 200px;
    height: 100%;
    display: inline-block;
}

.file-upload {
    display: none;
}

.circle {
    border-radius: 100% !important;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    top: 72px;
}

img {
    max-width: 100%;
    height: auto;
}

.p-image {
    position: absolute;
    color: #666666;
    transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
    top: 170px;
    right: 282px;
}

.p-image:hover {
    transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}

.upload-button {
    font-size: 1.2em;
}

.upload-button:hover {
    transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
    color: #999;
}