.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 9999;
}

.loading-screen .dot {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.6em;
  border-radius: 50%;
}

.loading-screen .dot::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  animation: spreadout 1.7s ease-out infinite;
}
@keyframes spreadout {
  50%,
  75% {
    transform: scale(2.5);
  }
  80%,
  100% {
    opacity: 0;
  }
}

.loading-screen .dot:nth-child(1) {
  background-color: #4285f5;
}
.loading-screen .dot:nth-child(2) {
  background-color: #ea4436;
}

.loading-screen .dot:nth-child(3) {
  background-color: #fbbd06;
}

.loading-screen .dot:nth-child(4) {
  background-color: #34a952;
}

.loading-screen .dot:nth-child(5) {
  background-color: #cf9fff;
}

.loading-screen .dot:nth-child(1)::before {
  animation-delay: 0.2s;
}
.loading-screen .dot:nth-child(2)::before {
  animation-delay: 0.4s;
}
.loading-screen .dot:nth-child(3)::before {
  animation-delay: 0.6s;
}
.loading-screen .dot:nth-child(4)::before {
  animation-delay: 0.8s;
}
.loading-screen .dot:nth-child(5)::before {
  animation-delay: 1s;
}
