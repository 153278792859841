.welcomeMessage {
  .message1 {
    color: #00b3d1;

    font-family: Albert Sans;
    font-size: 42.947px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    @media (max-width: 576px) {
      font-size: 32px;
      line-height: 38px;
    }
    /* 111.765% */
  }

  .message2 {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 42.947px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    @media (max-width: 576px) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  .message3 {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .upcoming-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .upcoming-ride-button {
      border-radius: 20.5px;
      border: 1px solid #abffb8;
      color: #c9ffd4;
      padding-block: 14px;
      padding-inline: 20px;
      text-align: center;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 15px;
    }

    .view-updates {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
    }
  }
}

// ----------- Next Ride Container--------------------

.next-ride-container {
  border-radius: 8px;
  border: 1px solid #2d2d2d;
  background: linear-gradient(110deg, #222 0%, #3a3a3a 56.22%, #121212 132.8%);

  .next-ride-image-container {
    display: flex;
    margin-left: 15px;
    padding-block: 10px;
    .nextRide-image {
      height: 42px;
      width: 42px;
      background-color: #c9ffd4;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .next-ride-text {
      color: #c9ffd4;
      font-family: Albert Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px; /* 188.889% */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }

  .next-stops-text-container {
    padding-block: 5px;
    .circle-rounded-stop-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .circle-rounded-stop {
        width: 10px;
        height: 10px;
        color: #00b3d1;
      }
    }

    .start-stop-text {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 8px;
    }

    .place-stop-container {
      display: flex;
      align-items: center;

      .place-rounded-stop {
        width: 12px;
        height: 14px;
        color: red;
      }
    }

    .end-stop-text {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      display: flex;
      align-items: center;
      padding-left: 8px;
    }
  }
}

// ----------- Upcoming Ride Container--------------------

.upcoming-ride-container {
  border-radius: 8px;
  border: 1px solid #2d2d2d;
  background: linear-gradient(110deg, #222 0%, #3a3a3a 56.22%, #121212 132.8%);

  .upcoming-ride-image-container {
    display: flex;
    margin-left: 15px;
    padding-block: 10px;
    .upcomingRide-image {
      height: 42px;
      width: 42px;
      background-color: #fff0bc;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upcoming-ride-text {
      color: #fff0bc;
      font-family: Albert Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }

  .upcoming-text-container {
    display: flex;
    justify-content: space-around;

    .text-1 {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
    }

    .text-2 {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
    }
  }
}

.driver-dashboard-main-container {
  margin-bottom: 0px;

  .dashboard-subcontainer-1 {
    padding-inline: 3rem;

    @media (max-width: 576px) {
      padding-inline: 25px;
      padding-block: 10px;
    }

    .dashboard-subcontainer-2 {
      display: flex;
      align-items: center;
      position: relative;
      color: white;
      padding-block: 30px;

      @media (max-width: 576px) {
        padding-block: 15px;
      }

      .dashboard-subcontainer-3 {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .current-ride-section {
    margin-inline: 90px;
    margin-top: 30px;

    @media (max-width: 576px) {
      margin-inline: 15px;
      margin-top: 6px;
    }

    height: calc(100vh - 100px);

    .current-ride-section-subcontainer {
      .real-data-exits-container {
        display: flex;
        flex-direction: column;

        .table-heading {
          color: #000;
          font-family: Albert Sans;
          font-size: 40px;
          font-weight: 600;
          margin-bottom: 5px;

          @media (max-width: 576px) {
            color: #000;
            font-family: Albert Sans;
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 5px;
            padding-top: 0px;
          }
        }

        .map-container {
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
          padding-top: 30px;
          padding-left: 23px;
          margin-top: 10px;

          @media (max-height: 576px) {
            margin-top: 5px;
            padding-top: 0px;
          }

          .route-details-heading {
            color: #000;
            font-family: Albert Sans;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;

            /* 114.286% */
          }

          .map-subcontainer {
            .start-end-map-boxes-container {
              display: flex;
              justify-content: space-between;
              margin-right: 24px;
              flex-direction: row;
              margin-top: 10px;

              @media (max-width: 576px) {
                flex-direction: column;
                margin-top: 5px;
              }

              .start-map-box {
                border-radius: 6px;
                border: 1px solid #e7e7e7;
                background: #f8f8f8;
                display: flex;
                align-items: center;
                padding: 20px;
                height: 55px;

                @media (max-width: 576px) {
                  padding: 12px;
                  margin-top: 10px;
                }

                .start-map-box-content {
                  color: #000;

                  font-size: 20px;
                  font-weight: 600;
                  display: flex;
                  align-items: center;

                  .start-map-box-text {
                    color: #000;

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                  }
                  .sphere {
                    width: 15px;
                    height: 15px;
                    color: #00b3d1;
                    margin-right: 10px;
                  }
                }
              }

              .end-map-box {
                border-radius: 6px;
                border: 1px solid #e7e7e7;
                background: #f8f8f8;
                display: flex;
                align-items: center;
                padding: 20px;
                height: 55px;

                @media (max-width: 576px) {
                  padding: 12px;
                  margin-top: 10px;
                }

                .end-map-box-content {
                  color: #000;
                  font-family: Albert Sans;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  display: flex;
                  align-items: center;

                  .end-map-box-text {
                    color: #000;
                    font-family: Albert Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 16px; /* 114.286% */
                  }

                  .map-icon-container {
                    margin-right: 10px;

                    .map-icon {
                      fill: #db1f28;
                    }
                  }
                }
              }
            }

            .ride-starttime-endtime-container-xs {
              margin-top: 0px;
              color: #505050;
              font-weight: 400;
              display: none;
              padding: 0px;

              @media (max-width: 576px) {
                display: flex;
                padding: 10px;
              }

              .ride-starttime-endtime-content {
                // display: flex;

                .start-text {
                  color: black;
                  font-family: Albert Sans;
                  font-size: 12px;
                  font-weight: 500;
                }
                .start-time {
                  color: #505050;
                  font-family: Albert Sans;
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }

            .start-journey-button-container {
              display: flex;
              margin-top: 12px;

              .start-journey-button {
                border-radius: 30px;
                background: #42ac02;
                color: #fefefe;
                text-align: center;
                font-family: Albert Sans;
                font-size: 18px;
                font-weight: 500;
                // sx={{ padding: { xs: "17px" } }}
                height: 55px;
                text-transform: none;
              }
            }
          }

          .ride-starttime-endtime-container-sm {
            margin-top: 14px;
            color: #0b0b0b;
            font-family: Poppins;

            display: flex;

            @media (max-width: 576px) {
              display: none;
            }

            .ride-starttime-endtime-content {
              display: flex;

              @media (max-width: 576px) {
                display: block;
              }
            }
            .start-text {
              color: black;
              font-family: Albert Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
            }
            .start-time {
              color: #505050;
              font-family: Albert Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
            }
          }

          .real-time-map-container {
            border-radius: 6px;
            border: 1px solid #e7e7e7;
            margin-top: 27px;
            height: 200px;
            overflow: hidden;
            /* This is important for object-fit to work properly */
            .real-time-map {
              border-radius: 6px;
              height: 100%;
              width: 100%;
              object-fit: cover;
              /* This property will make the image cover the entire area */
            }
          }
        }
      }
    }

    .schedule-not-found {
      font-size: 34px;

      .upcoming-navigation {
        cursor: pointer;
        text-decoration: underline;
        color: #00b3d1;
      }

      @media (max-width: 576px) {
        font-size: 22px;
      }
    }
  }

  .driver-schedule-update {
    position: absolute;
    border-radius: 8px;
    border: 1px solid #cbedf3;
    background: #e4fbff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11),
      0px 0px 24px 0px rgba(0, 0, 0, 0.06);
    max-height: 420px;
    padding: 15px;

    .driver-schedule-update-heading {
      color: #000;
      font-family: "Albert Sans";
      font-size: 20px;
      font-weight: 700;
    }

    .driver-schedule-update-sub-content {
      margin-top: 5px;
      color: #484848;
      font-family: "Albert Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
    }

    .driver-schedule-update-sub-email {
      color: #0061f3;
      font-family: "Albert Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      text-decoration-line: underline;
      margin-top: 10px;
    }

    .driver-schedule-update-sub-read-more {
      color: #000;
      font-family: "Albert Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-decoration-line: underline;
      margin-top: 8px;
    }

    .driver-schedule-update-sub-user-details {
      border-top: 2px solid #c2e5eb;
      margin-top: 7px;
      display: flex;
      align-items: center;
      padding: 5px;

      .driver-schedule-update-sub-user-details-image {
        height: 35px;
        width: 35px;
        background-color: black;
        border-radius: 100%;
        margin-top: 10px;
      }

      .driver-schedule-update-sub-user-details-content {
        margin-left: 10px;
        margin-top: 10px;

        .driver-schedule-update-sub-user-details-content-heading {
          color: #000;
          font-family: Albert Sans;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .driver-schedule-update-sub-user-details-content-subcontent {
          color: #000;
          font-family: Albert Sans;
          font-size: 15px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
  }
}
