.driver-upcoming-main-container {
  margin-inline: 50px;
  margin-top: 30px;
  height: calc(100vh - 100px);
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #eee;
  overflow: scroll;

  .cancel-ride-button {
    color: #fff;
    text-align: center;
    border-radius: 20.5px;
    background: #000;
    font-family: Albert Sans;
    font-weight: 400;
    text-transform: none;
  }

  .cancel-ride-button:hover {
    background: black;
  }

  @media (max-width: 560px) {
    margin-inline: 12px;
    border: none;
    margin-top: 10px;
  }

  .upcoming-ride-driver-main-header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    height: 70px;
    margin-bottom: 20px;

    @media (max-width: 576px) {
      height: 40px;
    }
  }

  .upcoming-ride-driver-heading {
    font-size: 30px;
    font-family: "Albert Sans";
    font-weight: 600;

    @media (max-width: 560px) {
      color: #000;
      font-family: Albert Sans;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      /* 184.615% */
    }
  }

  .table-hiding {
    .show-table {
      display: flex;
      flex-direction: column;

      @media (max-width: 576px) {
        display: none;
      }
    }

    .show-table-2 {
      display: none;
      flex-direction: column;

      @media (max-width: 576px) {
        display: block;
      }
    }

    .for-mobile-view {
      display: none;
      overflow: scroll;

      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        overflow: scroll;
      }

      .item-container {
        border: 1px solid #d9d8d8;
        border-radius: 7px;
        padding: 10px;
        margin-bottom: 10px;

        .date-container {
          .content {
            font-weight: bold;
            color: rgb(56, 56, 56);
            font-size: 14px;
          }
        }

        .item-name-container {
          .content {
            font-weight: bold;
            padding-block: 3px;
          }
        }

        .departure-time-container {
          display: flex;
          align-items: center;
          .head {
            font-size: 13px;
            font-weight: bold;
          }
          .content {
            font-size: 14px;
          }
        }

        .departure-address {
          display: flex;
          align-items: center;
          .head {
            font-size: 13px;
            font-weight: bold;
          }
          .content {
            font-size: 13px;
          }
        }

        .button-container {
          padding: 15px;
        }
      }
    }
  }

  .add-schedule-logo {
    background-color: #00b3d1;
    border-radius: 100%;
    color: white;
    height: 22px;
    width: 22px;
  }

  .add-schedule-button {
    font-family: "Albert Sans";
    text-decoration: underline;
    font-size: 17px;
    color: black;
    font-weight: 400;
    line-height: 16.9px;
    text-transform: none;
  }
}

//Card Styling
