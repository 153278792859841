.ImageViewCustom {
  cursor: pointer;
  overflow: hidden;

  .circle {
    border-radius: 100% !important;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    top: 72px;
    .profile-pic {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  }
}
