.schedule-update-modal {
  .title-container {
    position: relative;
    .title {
      font-family: Albert Sans;
      margin-inline: 15px;
      margin-top: 15px;
      color: #000;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .schedule-modal-body {
    .content {
      border-bottom: 1px solid #c2e5eb;
      max-height: 300px;
      overflow: scroll;

      .driver-schedule-update-sub-content {
        margin-inline: 15px;
        margin-block: 5px;
        font-size: 14px;
        color: #484848;
        font-family: Albert Sans;
      }
    }
  }

  .driver-schedule-update-sub-user-details {
    .driver-schedule-update-sub-user-details-image {
    }

    .driver-schedule-update-sub-user-details-content-heading {
      margin-inline: 15px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: bold;
      font-family: Albert Sans;
    }

    .driver-schedule-update-sub-user-details-content-subcontent {
      margin-inline: 15px;
      font-size: 12px;
      font-weight: bold;
    }
    .driver-schedule-update-sub-email {
      margin-inline: 15px;
      margin-bottom: 10px;
      color: blue;
      font-size: 12px;
      font-family: Albert Sans;
    }
  }
}
