.terms_condition {
  padding-right: 10px;
  cursor: pointer;

  .language_text {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-left: 15px;
    line-height: normal;
  }

  .terms_condition_text {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .language-icon {
    height: 15.11px;
  }
}

.nav-item1 {
  margin-left: 0px !important;
  position: relative;
  right: 13px;
}

.language-dropdown {
  color: white;
  cursor: pointer;

  .language-dropdown-text {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.profile-dropdown {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    height: 40px;
    width: 40px;
  }

  .profile-dropdown-text {
    padding-left: 5px;
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

$color_1: #333333;
$color_2: #ffffff;

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: $color_1;
  background: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background: #cccccc;
  }
}

.icon-button__badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: red;
  color: $color_2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.navbar-container {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 15px;

  @media (max-width: 576px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .menu-icon {
    display: none;

    @media (max-width: 576px) {
      display: block;
    }
  }

  .busteri-logo {
    margin-left: 30px;
    height: 45.248px;
    width: 155.453px;

    @media (max-width: 576px) {
      // style={{ height: "30px", width: "100px", marginLeft: "7px" }}
      height: 31.749px;
      width: 113px;
      margin-left: 7px;
    }
  }

  .start-end-shift-button {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    // width: 98px;
    padding-inline: 10px;
    height: 32px;
    border-radius: 20.5px;
    background: #00b3d1;
    text-transform: none;
  }
}
