.login-main-container {
  .css-1w74hc4 {
    margin-left: 0px !important;
  }
  .busteri-logo {
    width: 138px;
    height: 38.77px;

    @media (max-width: 576px) {
      width: 118.23px;
      height: 33.218px;
    }
  }

  .login-heading {
    font-family: Albert Sans;
    font-size: 42px;
    font-weight: 700;
    @media (max-width: 576px) {
      color: #000;
      font-family: Albert Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 54.617px; /* 170.677% */
    }
  }

  .email-textbox {
    background-color: #f8f8f8;
    margin-top: 20px;
    border-radius: 7.04px;
    border: 1px solid #e7e7e7;

    @media (max-width: 576px) {
      height: 42px;
      overflow: hidden;
    }
  }

  .checkbox-container {
    margin-block: 10px;
    font-size: 15.84px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 576px) {
      margin-block: 10px;
      color: #24252b;
      font-family: Albert Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .login-button {
    background-color: black;
    color: white;
    height: 64.25px;
    font-size: 17.6px;

    @media (max-width: 576px) {
      border-radius: 7.041px;
      background: #000;
      height: 44.55px;
      color: #fff;
      text-align: center;
      font-family: Albert Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .trademark {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: #000000;
    font-size: 12.34px;

    @media (max-width: 576px) {
      color: #505050;
      text-align: center;
      font-family: Albert Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px; /* 200% */
    }
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    @media (max-width: 576px) {
      font-size: 13px;
      padding: 13px;
    }
  }
}

.css-1fiywmn-MuiGrid-root {
  margin-left: 0px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 13px !important;
  padding: 13px !important;
}
