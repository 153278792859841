// @import "@tinymce/tinymce-react";

.notifications-master-container {
  margin-inline: 50px;
  margin-top: 30px;
  height: 80vh;
  border-radius: 25px;
  overflow: hidden;
  border: 2px solid #eee;
  overflow: scroll;

  .heading-container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;

    .heading-content-container {
      .heading-text {
        color: #24252b;
        font-family: Albert Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
      }
    }

    .options-container {
      .options-form-control {
        .options-header-container {
          .option-headingtext {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
  }

  .body-container {
    padding-inline: 25px;
    overflow-y: scroll;
    overflow-x: scroll;
    border-top: 1px solid #d5d4d4;
    // border-top-right-radius: 10px;
    // border-top-left-radius: 10px;
    // padding-block: 20px;
    height: 65vh;

    .sendButton {
      cursor: pointer;
      width: 117px;
      height: 44px;
      flex-shrink: 0;
      border-radius: 22px;
      border: 1px solid #000;
      display: flex;
      background-color: #000;
      color: white;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
    }

    .sendButton:disabled {
      background-color: #3b3b3b; /* Change the background color to a disabled state color */
      border: 1px solid #454545; /* Change the border color for disabled state */
      color: #898888; /* Change the text color for disabled state */
      cursor: not-allowed; /* Change the cursor to indicate it's disabled */
      /* You can add more styles for the disabled state as needed */
    }

    .receptent-container {
      .receptent-text-container {
        padding-block: 7px;
        .receptent-text {
          font-family: Albert Sans;
          font-size: 18px;
        }
      }
    }

    .subject-container {
      .subject-text-container {
        padding-block: 7px;
        .subject-text {
          font-family: Albert Sans;
          font-size: 18px;
        }
      }

      .input-container {
      }
    }

    .body-message-container {
      .message-text-container {
        padding-block: 7px;
        .message-text {
          font-family: Albert Sans;
          font-size: 18px;
        }
      }

      .editor-container {
      }
    }
  }

  .message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    // border-top-right-radius: 10px;
    // border-top-left-radius: 10px;
    color: rgb(179, 174, 174);
    font-size: 32px;
    border-top: 2px solid #d5d4d4;
  }
}
