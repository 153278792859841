.allfooterMainContainer {
  height: 100%;

  .FormFiledContainer {
    overflow: scroll;
    height: 85%;
  }

  .formfooterButton {
    margin-right: 10px;
    border-top: 2px solid rgb(231, 231, 231);
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .saveButton {
      cursor: pointer;
      width: 117px;
      height: 44px;
      flex-shrink: 0;
      border-radius: 22px;
      border: 1px solid #000;
      display: flex;
      background-color: #000;
      color: white;
      justify-content: center;
      align-items: center;
    }
  }
}

.forminputTitle {
  color: #67c6d6;
  font-family: Albert Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 6px;
}

.errorMessageTitle {
  color: red;
  font-family: Albert Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 6px;
}

.footerButton {
  display: flex;
  align-items: center;
  padding-top: 30px;

  .saveButton {
    cursor: pointer;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    display: flex;
    background-color: #000;
    color: white;
    justify-content: center;
    align-items: center;
  }

  .cancelButton {
    cursor: pointer;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    background: #fff;
  }
}

.schedule-main-container {
  margin-inline: 50px;
  margin-top: 30px;
  height: calc(100vh - 100px);
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #eee;

  @media (max-width: 560px) {
    margin-inline: 12px;
    border: none;
    margin-top: 10px;
  }

  .schedule-main-header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    height: 70px;
    margin-bottom: 20px;
  }

  .schedule-heading {
    font-size: 30px;
    font-family: "Albert Sans";
    font-weight: 600;

    @media (max-width: 560px) {
      color: #000;
      font-family: Albert Sans;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px; /* 184.615% */
    }
  }

  .add-schedule-logo {
    background-color: #00b3d1;
    border-radius: 100%;
    color: white;
    height: 22px;
    width: 22px;
  }

  .add-schedule-button {
    font-family: "Albert Sans";
    text-decoration: underline;
    font-size: 17px;
    color: black;
    font-weight: 400;
    line-height: 16.9px;
    text-transform: none;
  }

  .detail-panel-container {
    display: flex;

    .arival-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .arrival-heading {
        background-color: #eef9ff;
        padding-block: 10px;
        width: 100%;
        text-align: center;
        color: #404040;
        font-family: Albert Sans;
        font-size: 12px;
        font-weight: 600;
      }

      .arrival-content {
        color: #505050;
        font-family: Albert Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-block: 8px;
      }
    }

    .vehicle-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .vehicle-heading {
        background-color: #eef9ff;
        padding-block: 10px;
        width: 100%;
        text-align: center;
        color: #404040;
        font-family: Albert Sans;
        font-size: 12px;
        font-weight: 600;
      }
      .vehicle-content {
        color: #505050;
        font-family: Albert Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-block: 8px;
      }
    }

    .driver-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .driver-heading {
        background-color: #eef9ff;
        padding-block: 10px;
        width: 100%;
        text-align: center;
        color: #404040;
        font-family: Albert Sans;
        font-size: 12px;
        font-weight: 600;
      }

      .driver-content {
        color: #505050;
        font-family: Albert Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-block: 8px;
      }
    }
  }
}

//Card Styling

.card-container {
  border-top: 1px solid #ebebeb;
  .passenger-name {
    color: #505050;
    font-family: Albert Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 220% */
  }

  .place-lable {
    color: #404040;
    font-family: Albert Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .place-details {
    color: #505050;
    font-family: Albert Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 220% */
  }
}
