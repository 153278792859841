.welcomeMessage {
  .message1 {
    color: #00b3d1;

    font-family: Albert Sans;
    font-size: 42.947px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    @media (max-width: 576px) {
      font-size: 32px;
      line-height: 38px;
    }
    /* 111.765% */
  }

  .message2 {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 42.947px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    @media (max-width: 576px) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  .message3 {
    color: #fefefe;
    font-family: Albert Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .upcoming-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .upcoming-ride-button {
      border-radius: 20.5px;
      border: 1px solid #abffb8;
      color: #c9ffd4;
      padding-block: 14px;
      padding-inline: 20px;
      text-align: center;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 15px;
    }

    .view-updates {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
    }
  }
}

// ----------- Next Ride Container--------------------

.next-ride-container {
  border-radius: 8px;
  border: 1px solid #2d2d2d;
  background: linear-gradient(110deg, #222 0%, #3a3a3a 56.22%, #121212 132.8%);

  .next-ride-image-container {
    display: flex;
    margin-left: 15px;
    padding-block: 10px;
    .nextRide-image {
      height: 42px;
      width: 42px;
      background-color: #c9ffd4;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .next-ride-text {
      color: #c9ffd4;
      font-family: Albert Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px; /* 188.889% */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }

  .next-stops-text-container {
    padding-block: 5px;
    .circle-rounded-stop-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .circle-rounded-stop {
        width: 10px;
        height: 10px;
        color: #00b3d1;
      }
    }

    .start-stop-text {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 8px;
    }

    .place-stop-container {
      display: flex;
      align-items: center;

      .place-rounded-stop {
        width: 12px;
        height: 14px;
        color: red;
      }
    }

    .end-stop-text {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      display: flex;
      align-items: center;
      padding-left: 8px;
    }
  }
}

// ----------- Upcoming Ride Container--------------------

.upcoming-ride-container {
  border-radius: 8px;
  border: 1px solid #2d2d2d;
  background: linear-gradient(110deg, #222 0%, #3a3a3a 56.22%, #121212 132.8%);

  .upcoming-ride-image-container {
    display: flex;
    margin-left: 15px;
    padding-block: 10px;
    .upcomingRide-image {
      height: 42px;
      width: 42px;
      background-color: #fff0bc;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upcoming-ride-text {
      color: #fff0bc;
      font-family: Albert Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }

  .upcoming-text-container {
    display: flex;
    justify-content: space-around;

    .text-1 {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
    }

    .text-2 {
      color: #fefefe;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
    }
  }
}

.driver-schedule-update {
  position: absolute;
  border-radius: 8px;
  border: 1px solid #cbedf3;
  background: #e4fbff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11),
    0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  max-height: 320px;
  padding: 15px;

  .driver-schedule-update-heading {
    color: #000;
    font-family: "Albert Sans";
    font-size: 20px;
    font-weight: 700;
  }

  .driver-schedule-update-sub-content {
    margin-top: 5px;
    color: #484848;
    font-family: "Albert Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .driver-schedule-update-sub-email {
    color: #0061f3;
    font-family: "Albert Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    text-decoration-line: underline;
    margin-top: 10px;
  }

  .driver-schedule-update-sub-read-more {
    color: #000;
    font-family: "Albert Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    margin-top: 8px;
  }

  .driver-schedule-update-sub-user-details {
    border-top: 2px solid #c2e5eb;
    margin-top: 7px;
    display: flex;
    align-items: center;
    padding: 5px;

    .driver-schedule-update-sub-user-details-image {
      height: 35px;
      width: 35px;
      background-color: black;
      border-radius: 100%;
      margin-top: 10px;
    }

    .driver-schedule-update-sub-user-details-content {
      margin-left: 10px;
      margin-top: 10px;

      .driver-schedule-update-sub-user-details-content-heading {
        color: #000;
        font-family: Albert Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .driver-schedule-update-sub-user-details-content-subcontent {
        color: #000;
        font-family: Albert Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }
}

.dashboard-main-container {
  .todays-ride-section {
    .todays-ride-heading {
      color: #000;
      font-family: Albert Sans;
      font-size: 42.947px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 19px;
    }
  }

  .detail-panel-container {
    display: flex;

    .arival-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .arrival-heading {
        background-color: #eef9ff;
        padding-block: 10px;
        width: 100%;
        text-align: center;
        color: #404040;
        font-family: Albert Sans;
        font-size: 12px;
        font-weight: 600;
      }

      .arrival-content {
        color: #505050;
        font-family: Albert Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-block: 8px;
      }
    }

    .vehicle-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .vehicle-heading {
        background-color: #eef9ff;
        padding-block: 10px;
        width: 100%;
        text-align: center;
        color: #404040;
        font-family: Albert Sans;
        font-size: 12px;
        font-weight: 600;
      }
      .vehicle-content {
        color: #505050;
        font-family: Albert Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-block: 8px;
      }
    }

    .driver-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .driver-heading {
        background-color: #eef9ff;
        padding-block: 10px;
        width: 100%;
        text-align: center;
        color: #404040;
        font-family: Albert Sans;
        font-size: 12px;
        font-weight: 600;
      }

      .driver-content {
        color: #505050;
        font-family: Albert Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-block: 8px;
      }
    }
  }
}
