.uploadModalMain {
  width: 495px;
  height: 500px;

  .uploadmainHeaderupload {
    width: 495px;

    .title {
      color: #24252b;
      font-family: Albert Sans;
      font-size: 32px;
      font-weight: 600;
    }

    .subtitle {
      color: #b6b6b6;
      font-family: Albert Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 9px;
      padding-top: 5px;
    }
  }

  .customModalBody {
    height: 70%;
    padding-left: 28px;
    padding-right: 28px;
    overflow: scroll;
  }

  .footerButton {
    display: flex;
    height: 15%;
    align-items: center;
    padding-left: 23px;
  }
}

.uploadContainer {
  .css-3laifl {
    border-radius: 14px;
    height: 323px;
    width: 495px;
  }

  .saveButton {
    cursor: pointer;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    display: flex;
    background-color: #000;
    color: white;
    justify-content: center;
    align-items: center;
  }

  .cancelButton {
    cursor: pointer;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    background: #fff;
    color: black;
  }
}
