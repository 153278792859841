.MuiTableRow-root {
  border-bottom: none;
}

.common-table-main-container {
  .header-main-container {
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;

    .header-heading {
      color: #24252b;
      font-family: Albert Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 29px;
    }

    .header-add-logo {
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      border-radius: 50px;
      background: #00b3d1;
    }

    .header-add-button {
      color: #000;
      font-family: Albert Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }

  .common-table {
    height: calc(100vh - 300px);
    overflow: scroll;

    .css-1mbunh-MuiPaper-root {
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #ebebeb;

      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
    }

    .css-kga3m3-MuiTableCell-root {
      border-bottom: 0px;
      padding: 10px 10px;
      // padding: 10px;
    }

    .css-1mbunh-MuiPaper-root {
      box-shadow: none;
      border-top: 2px solid #eeeeee;
      border-bottom: 2px solid #eeeeee;
    }

    .css-jmm5fp-MuiTableCell-root {
      border-bottom: none;
    }

    .css-sx4x5h-MuiTableCell-root {
      height: 48px;
      border-radius: 10px, 10px, 0px, 0px;
    }

    .common-table .css-sx4x5h-MuiTableCell-root {
      height: 48px;
      border-radius: 10px, 10px, 0px, 0px;
    }

    .css-sx4x5h-MuiTableCell-root {
      line-height: 1rem;
      padding-block: 1px;
    }

    .MuiToolbar-root,
    .MuiToolbar-gutters,
    .MuiToolbar-regular,
    .MTableToolbar-root-20,
    .css-hyum61k-MuiToolbar-root {
      display: none;
    }
  }
}
