.detail-view-driver-container {
  .detail-view-driver-container-2 {
    display: block;

    @media (max-width: 576px) {
      display: none;
    }

    .desktop-view-container {
      margin-top: 5px;
      height: calc(
        100vh - 100px
      ); // Use appropriate values for your specific case
      background-color: white;
      border-radius: 14px;
      overflow: hidden;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 15px;
      border-top: 1px solid #e5eef3;

      .desktop-view-container-2 {
        display: flex;
        justify-content: space-between;

        .table-heading {
          font-size: 20px;
          font-family: "Albert Sans", sans-serif; // Replace "sans-serif" with a fallback if needed
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }
  }

  .card-container {
    border-top: 1px solid #ebebeb;
    display: none;

    @media (max-width: 576px) {
      display: flex;
    }

    .card-sub-container {
      padding-block: 10px;
      padding-inline: 19px;
      display: flex;
      justify-content: space-around;
      border: 1px solid #ebebeb;

      .card-body-container {
        .card-body-sub-container {
          display: flex;

          .card-from-container {
            display: flex;
            align-items: center;
            .place-lable {
              color: #565555;
              font-family: Albert Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }

        .card-body-sub-container-2 {
          display: flex;
          margin-top: 6px;

          .card-body-to {
            display: flex;
            align-items: center;

            .place-lable {
              color: #565555;
              font-family: Albert Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }

        .card-body-button-container {
          display: flex;

          .item-canceled {
            font-size: 14px;
            font-weight: 600;
          }

          .cancel-ride-button {
            margin-top: 10px;
            padding-inline: 15px;
            padding-block: 5px;
          }
        }
      }

      .passenger-name {
        color: #505050;
        font-family: Albert Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        padding-block: 10px;
        font-weight: 600;
        /* 220% */
      }

      .place-details {
        color: #505050;
        font-family: Albert Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 220% */
      }
    }
  }
}
