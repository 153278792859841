.report-container {
  .report-heading-container {
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    .report-heading {
      color: #24252b;
      font-family: Albert Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 29px;
    }
  }

  .report-body-container {
    border: 2px solid #eee;
    height: 100%;

    .form-container {
      display: flex;
      margin: 10px;
      border-radius: 15px;
      padding: 15px;

      .form-sub-container {
        display: flex;
        justify-content: space-evenly;
        padding-inline: 10px;
        .asteric {
          color: red;
          text-align: center;
          vertical-align: middle;
          margin-left: 5px;
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .saveButton {
          cursor: pointer;
          width: 140px;
          height: 54px;
          border-radius: 22px;
          border: 1px solid #000;
          display: flex;
          background-color: #000;
          color: white;
          justify-content: center;
          align-items: center;
          border-radius: 22px;
          background: #000;
        }
      }
    }
  }
}

.table-conatainer {
  .table-sub-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #eee;

    .reportDetails {
      color: #24252b;
      font-size: 30px;
      font-weight: 600;
    }

    .button-donwload {
      margin-right: 50px;
      border-radius: 20px;
      &:hover {
        background-color: black;
      }

      color: white;
      background-color: black;
    }
  }
}

.driver-upcomingrides-container {
  margin-inline: 50px;
  margin-top: 30px;
  height: calc(100vh - 100px);
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #eee;

  @media (max-width: 560px) {
    margin-inline: 12px;
    border: none;
    margin-top: 10px;
  }

  .heding-container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    padding-left: 20px;

    .schedule-heading {
      font-size: 30px;
      font-family: Poppins;
      font-weight: 600;

      @media (max-width: 560px) {
        color: #000;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .table-container {
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
      display: none;
    }
  }

  .table-container-2 {
    display: none;

    @media (max-width: 576px) {
      display: block;
    }
  }

  .detail-container-desktopscreen {
    display: block;
    @media (max-width: 576px) {
      display: none;
    }

    .detail-container-2 {
      margin-top: 5px;
      background-color: white;
      border-radius: 14px;
      overflow: hidden;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 15px;
      border-top: 1px solid #e5eef3;

      .detail-container-3 {
        display: flex;
        justify-content: space-between;

        .detail-heading {
          font-size: 20px;
          font-family: Poppins;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }
  }

  .card-container {
    display: none;
    font-family: Poppins;

    @media (max-width: 576px) {
      display: flex;
    }

    .card-container-2 {
      padding-block: 10px;
      padding-inline: 19px;
      display: flex;
      justify-content: space-around;
      border: 1px solid #ebebeb;

      .card-subcontainer {
        .card-subcontainer-2 {
          display: flex;

          .from-container {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .card-subcontainer-3 {
      display: flex;
      margin-top: 6px;
    }
    .passenger-name {
      font-family: Poppins;
      font-weight: 600;
      border: 1px solid white;
      border-radius: 10px;
      text-align: center;
      background: #00b3d1;
      color: white;
      padding: 10px;
    }

    .place-lable {
      font-family: Poppins;
    }

    .place-details {
      font-family: Poppins;
      font-weight: 500;
    }
  }
}
