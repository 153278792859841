.allfooterMainContainer {
  height: 100%;

  .formContainer {
    height: 100%;
    .FormFiledContainer {
      overflow: scroll;
      height: 85%;
    }
  }

  .formfooterButton {
    margin-right: 10px;
    border-top: 2px solid rgb(231, 231, 231);
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .saveButton {
      cursor: pointer;
      width: 117px;
      height: 44px;
      flex-shrink: 0;
      border-radius: 22px;
      border: 1px solid #000;
      display: flex;
      background-color: #000;
      color: white;
      justify-content: center;
      align-items: center;
    }
  }
}

.forminputTitle {
  color: #67c6d6;
  font-family: Albert Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 6px;
}

.errorMessageTitle {
  color: red;
  font-family: Albert Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 6px;
}

.footerButton {
  display: flex;
  align-items: center;
  padding-top: 30px;

  .saveButton {
    cursor: pointer;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    display: flex;
    background-color: #000;
    color: white;
    justify-content: center;
    align-items: center;
  }

  .cancelButton {
    cursor: pointer;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    background: #fff;
  }
}

.schoolUser-main-container {
  margin-inline: 50px;
  margin-top: 30px;
  height: calc(100vh - 100px);
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #eee;

  .schoolUser-main-header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    height: 70px;
    margin-bottom: 20px;
  }

  .schoolUser-heading {
    font-size: 30px;
    font-family: "Albert Sans";
    font-weight: 600;
  }

  .schoolUser-logo {
    background-color: #00b3d1;
    border-radius: 100%;
    color: white;
    height: 22px;
    width: 22px;
  }

  .schoolUser-button {
    font-family: "Albert Sans";
    text-decoration: underline;
    font-size: 17px;
    color: black;
    font-weight: 400;
    line-height: 16.9px;
    text-transform: none;
  }
}
