.mainContainerProfile {
  margin-top: 30px;

  @media (max-width: 576px) {
    margin-top: 20px;
  }
  .saveButton {
    cursor: pointer;
    border-radius: 22px;
    border: 1px solid #000;
    background-color: #000;
    color: white;
    padding-inline: 30px;
  }

  .main-container-sub-header {
    display: flex;
    justify-content: center;
    align-items: center;

    .sub-container-1 {
      background-color: white;
      border: 1px solid #e7e7e7;
      border-radius: 25px;

      @media (max-width: 576px) {
        border-radius: 20px;
      }

      .heading-container {
        border-bottom: 1px solid #e7e7e7;
        padding-block: 16px;
        padding-inline: 28px;

        @media (max-width: 576px) {
          padding-block: 15px;
          padding-inline: 20px;
        }

        .user-name {
          font-family: Albert Sans;
          font-weight: 600;
          font-size: 32px;
          @media (max-width: 576px) {
            color: #24252b;
            font-family: Albert Sans;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
          }
        }

        .heading-sub-text {
          font-size: 16px;
          color: #b6b6b6;

          @media (max-width: 576px) {
            font-size: 12px;
            color: #b6b6b6;
          }
        }
      }

      .image-container {
        padding-block: 16px;
        padding-inline: 28px;
        display: flex;

        .image-sub-container-1 {
          height: 92px;
          width: 92px;
          background-color: gray;
          border-radius: 100%;

          @media (max-width: 576px) {
            height: 76px;
            width: 76px;
            background-color: gray;
            border-radius: 100%;
          }

          .image-user {
            height: 92px;
            width: 92px;
            background-color: gray;
            border-radius: 100%;

            @media (max-width: 576px) {
              height: 76px;
              width: 76px;
              background-color: gray;
              border-radius: 100%;
            }
          }
        }
        .image-user-name {
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 576px) {
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
          }
          .name {
            font-weight: 600;
            font-size: 24px;

            @media (max-width: 576px) {
              font-weight: 600;
              font-size: 18px;
            }
          }
        }
      }

      .form-container {
        padding-block: 16px;
        padding-inline: 28px;
        display: flex;

        @media (max-width: 576px) {
          padding-block: 0px;
        }
      }
      .footerButtons {
        padding-block: 16px;
        padding-inline: 28px;
        display: flex;
      }
    }

    .red-dot {
      color: red;
      text-align: center;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}
