.allfooterMainContainer {
  height: 100%;
  .formContainer {
    height: 100%;
    .FormFiledContainer {
      overflow: scroll;
      height: 85%;

      .formFilledSubContainer {
        margin-bottom: 30px;
      }
    }

    .asteric {
      color: red;
      margin-left: 5px;
      text-align: center;
      vertical-align: middle;
    }

    .formfooterButton {
      margin-right: 10px;
      border-top: 2px solid rgb(231, 231, 231);
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .saveButton {
        cursor: pointer;
        width: 117px;
        height: 44px;
        flex-shrink: 0;
        border-radius: 22px;
        border: 1px solid #000;
        display: flex;
        background-color: #000;
        color: white;
        justify-content: center;
        align-items: center;
      }

      .saveButton:hover {
        background-color: black;
      }
    }
  }
}

.forminputTitle {
  color: #67c6d6;
  font-family: Albert Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 6px;
}

.errorMessageTitle {
  color: red;
  font-family: Albert Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 6px;
}

.footerButton {
  display: flex;
  align-items: center;
  padding-top: 30px;

  .saveButton {
    cursor: pointer;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    display: flex;
    background-color: #000;
    color: white;
    justify-content: center;
    align-items: center;
  }

  .cancelButton {
    cursor: pointer;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 22px;
    border: 1px solid #000;
    background: #fff;
  }
}

.deleteIcon {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.cancelIcon {
  height: 20px;
  width: 20px;
}
