.modal-container {
  .end-journey-text {
    color: #24252b;
    text-align: center;
    font-family: Albert Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 106.667% */
    margin-top: 11px;
  }

  .yes-button {
    border-radius: 27.379px;
    width: 120.507px;
    height: 40.759px;
    background: #000;
    color: #fefefe;
    text-align: center;
    font-family: Albert Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  .no-button {
    border-radius: 27.379px;
    border: 1px solid #000;
    width: 120.507px;
    height: 40.759px;
    background: #fff;
    color: #000;
    text-align: center;
    font-family: Albert Sans;
    font-size: 3;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .buttons-container {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
