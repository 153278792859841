.policies-container {
  height: 100%;
  background-color: white;
  margin: 80px;
  margin-top: 40px;

  @media (max-width: 576px) {
    margin: 20px;
    margin-top: 20px;
  }

  .policies-container-2 {
    border-radius: 14px;
    border: 1px solid #eee;
    background: #fff;
  }

  .policies-container-3 {
  }

  .policies-heading-container {
    border-bottom: 1px solid #e7e7e7;

    .policies-heading-text {
      padding-inline: 26px;
      padding-block: 20px;
      color: #24252b;
      font-family: Albert Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      @media (max-width: 576px) {
        color: #24252b;
        font-family: Albert Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        padding-inline: 22px;
        padding-block: 15px;
      }
    }
  }

  .policies-subcontent-container {
    margin-top: 50px;
    padding-inline: 40px;
    @media (max-width: 576px) {
      margin-top: 20px;
      padding-inline: 20px;
    }

    .policies-subcontent-container-2 {
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 15px;
      margin-bottom: 22px;
      .policies-subcontent-headingtext {
        color: #000;
        font-family: Albert Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media (max-width: 576px) {
          color: #000;
          font-family: Albert Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .policies-subcontent-text {
        color: #000;
        font-family: Albert Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        margin-top: 10px;
        @media (max-width: 576px) {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}
